import logo from './logo.svg';
import Devices from './Devices';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ButtonAppBar from './components/ButtonAppBar';
import config from './config.json'
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import SignIn from './components/SignIn';
import { Page404 } from './components/Page404';
import { Button } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signin" element={<SignIn />} />
        {/*<Route path="*" element={<Page404 />} />*/}
        <Route
          path="/"
          element={
            <div className="App">
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <header className="appHeader">
                  <ButtonAppBar />
                  <Devices url={""} />
                </header>
              </ThemeProvider>
            </div>
          }
        />
        <Route
          path="/XR"
          element={
            <div className="App">
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <header className="appHeader">
                  <ButtonAppBar />
                  <Devices url={""} />
                </header>
              </ThemeProvider>
            </div>
          }
        >
          <Route
            path=":SubCategory"
            element={
              <div className="App">
                <ThemeProvider theme={darkTheme}>
                  <CssBaseline />
                  <header className="appHeader">
                    <ButtonAppBar />
                    <Devices url={""} />
                  </header>
                </ThemeProvider>
              </div>
            }
          >
            <Route
              path=":deviceId"
              element={
                <div className="App">
                  <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <header className="appHeader">
                      <ButtonAppBar />
                      <Devices url={""} />
                    </header>
                  </ThemeProvider>
                </div>
              }
            ></Route>
          </Route>
        </Route>

        <Route
          path="/IOT"
          element={
            <div className="App">
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <header className="appHeader">
                  <ButtonAppBar />
                  <Devices url={""} />
                </header>
              </ThemeProvider>
            </div>
          }
        >
          <Route
            path=":SubCategory"
            element={
              <div className="App">
                <ThemeProvider theme={darkTheme}>
                  <CssBaseline />
                  <header className="appHeader">
                    <ButtonAppBar />
                    <Devices url={""} />
                  </header>
                </ThemeProvider>
              </div>
            }
          >
            <Route
              path=":deviceId"
              element={
                <div className="App">
                  <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <header className="appHeader">
                      <ButtonAppBar />
                      <Devices url={""} />
                    </header>
                  </ThemeProvider>
                </div>
              }
            ></Route>
          </Route>
        </Route>
        <Route
          path="/3D"
          element={
            <div className="App">
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <header className="appHeader">
                  <ButtonAppBar />
                  <Devices url={""} />
                </header>
              </ThemeProvider>
            </div>
          }
        >
          <Route
            path=":SubCategory"
            element={
              <div className="App">
                <ThemeProvider theme={darkTheme}>
                  <CssBaseline />
                  <header className="appHeader">
                    <ButtonAppBar />
                    <Devices url={""} />
                  </header>
                </ThemeProvider>
              </div>
            }
          >
            <Route
              path=":deviceId"
              element={
                <div className="App">
                  <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <header className="appHeader">
                      <ButtonAppBar />
                      <Devices url={""} />
                    </header>
                  </ThemeProvider>
                </div>
              }
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
