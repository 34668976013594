import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function ButtonAppBar() {
  const navigate = useNavigate();
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Button color="inherit" onClick={() => window.location.replace(`/`)}>Home</Button>
            <Typography variant="h6" component="div" style={{alignItems: "right"}} sx={{ flexGrow: 1 }}>
              {localStorage.getItem("username") && localStorage.getItem("username")}
            </Typography>
            {localStorage.getItem("username") ?
              <Button color="inherit" onClick={() => {
                localStorage.clear(); 
                navigate("/")
              }
              }>Logout</Button>
              :
              <Button color="inherit" onClick={() => navigate("/signin")}>Login</Button>}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }