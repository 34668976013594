import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import eakr from "./static/eakr.png";
import vipuvoimaa from "./static/vipuvoimaa.png";
import seamk from "./static/seamk.jpg";
import vrIcon from "./static/vr-icon.svg";
import iotIcon from "./static/iot2-icon.svg";
import threeDeeIcon from "./static/threeDee-icon.svg";
import config from './config.json';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import EnhancedTable from "./components/EnhancedTable";
import { format } from 'date-fns';
import { flexbox } from "@mui/system";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TransitionGroup } from 'react-transition-group';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& input': {
      color: 'white',
    },
    '& .MuiInputBase-input': {
      color: 'white', // Adding this to target the base input style directly
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main, // Label color when focused
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main, // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main, // Border color when focused
      },
    },
  }));

export default function Devices(props) {

  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMainSite, setMainSite] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [reserverName, setReserverName] = useState("");
  const [reserveDate, setReserveDate] = useState(Date());

  const FRUITS = [
    '🍏 Apple',
    '🍌 Banana',
    '🍍 Pineapple',
    '🥥 Coconut',
    '🍉 Watermelon',
  ];

  const [open, setOpen] = useState(false);
  const [newItemOpen, setNewItemOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newDeviceType, setNewDeviceType] = useState("");
  const [newDeviceName, setNewDeviceName] = useState("");
  const [newDeviceDesc, setNewDeviceDesc] = useState("");
  const [newDeviceQuantity, setNewDeviceQuantity] = useState("");
  const [newDeviceLocation, setNewDeviceLocation] = useState("");
  const [newDeviceStickerSize, setNewDeviceStickerSize] = useState("");
  const [newDeviceSupplier, setNewDeviceSupplier] = useState("");
  const [editDeviceType, setEditDeviceType] = useState("");
  const [editDeviceName, setEditDeviceName] = useState("");
  const [editDeviceDesc, setEditDeviceDesc] = useState("");
  const [editDeviceQuantity, setEditDeviceQuantity] = useState("");
  const [editDeviceLocation, setEditDeviceLocation] = useState("");
  const [editDeviceStickerSize, setEditDeviceStickerSize] = useState("");
  const [editDeviceSupplier, setEditDeviceSupplier] = useState("");
  const [fruitsInBasket, setFruitsInBasket] = React.useState(FRUITS.slice(0, 3));
  const [drawerOpen, setDrawerOpen] = useState({});
  const [reFetch, setReFetch] = useState(false);

  
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewItemOpen = () => {
    setNewItemOpen(true);
  };

  const handleNewItemClose = () => {
    setNewItemOpen(false);
  };

  function renderItem({ item, handleRemoveFruit }) {
    return (
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            title="Delete"
            onClick={() => handleRemoveFruit(item)}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemText primary={item} />
      </ListItem>
    );
  }

  function renderInfo(item) {
    console.log("rednerInfotesti: ", item, drawerOpen[item._id])
    console.log("tässä siis drawerOpen: ", drawerOpen)
    return (
      <div>
      {(drawerOpen[item._id] && drawerOpen[item._id] === true) && (
        <div>
        <ListItem><ListItemText primary={item.info.description} /></ListItem>
        <ListItem><ListItemText primary={item.info.qrURL} /></ListItem>
        <ListItem><ListItemText primary={item.info.quantity} /></ListItem>
        <ListItem><ListItemText primary={item.info.returnDate} /></ListItem>
        <ListItem><ListItemText primary={item.info.stickerSize} /></ListItem>
        <ListItem><ListItemText primary={item.info.supplier} /></ListItem>
        </div>
      )}
      </div>
    );
  }

  const handleAddFruit = (id) => {
    console.log("addfruitista", id)
    /*const nextHiddenItem = FRUITS.find((i) => !fruitsInBasket.includes(i));
    if (nextHiddenItem) {
      setFruitsInBasket((prev) => [nextHiddenItem, ...prev]);
    }*/
    setDrawerOpen({ 
     ...drawerOpen, [id]: drawerOpen[id] === undefined ? true : !drawerOpen[id]
    })
  };

  const handleRemoveFruit = (item) => {
    setFruitsInBasket((prev) => [...prev.filter((i) => i !== item)]);
  };

  const addFruitButton = (
    <Button
      variant="contained"
      //disabled={fruitsInBasket.length >= FRUITS.length}
      onClick={handleAddFruit}
    >
      Add fruit to basket
    </Button>
  );

  const serverUrl = config.SERVER_URL;
  const clientURL = config.CLIENT_URL;

  const currentPath = window.location.href.split("/");
  currentPath.splice(0, 3);
  const currentPathString = currentPath.join("/")

  //console.log("currentpath: ", currentPathString)

  useEffect(() => {
    setFetchError(false);
    setLoading(true);
    let currentUrl = window.location.href.split("/");
    currentUrl.splice(0, 3);
    const newUrl = currentUrl.join("/")
    const fetchUrl = `${serverUrl}/${newUrl}`
    console.log("fetchUrl: ", fetchUrl)

    //currentUrl[2] = serverUrl;

    //const newUrl = currentUrl.join("/");
    //console.log("newUrl:", newUrl)
    //const newUrl = currentUrl.slice(0, currentUrl.length-1).join("/");
    fetch(fetchUrl)
      .then((res) => res.json())
      .then((data) => {
        console.log("fetched data: ", data)
        setLoading(false);
        //setFetched(true);
        if (window.location.href === `${clientURL}/`) {
          //console.log("res: ", data.Devices);
          setData({ type: "mainSite", result: data.Devices })
        } else {
          //console.log("res: ", data.resultData);
          setData({ type: data.type, result: data.resultData });
          console.log("datatyyppi: ", data.type)
        }

      }).catch(err => {
        setLoading(false);
        setFetchError(true);
        console.log("fetch error: ", err)
      });

    //}
  }, [props.url, reFetch]);

  const reserveItem = async (isReserved) => {
    let currentUrl = window.location.href.split("/");
    currentUrl.splice(0, 3);
    console.log("reserveinfo: ", isReserved)
    //reserveInfo = {asd: 9}
      fetch(`${serverUrl}/reserveItem`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({name: isReserved === true ? reserverName : "", path: currentUrl, deviceName: data.result.name, returnDate: isReserved === false ? null : reserveDate})
      })
      .then((res) => res.json())
      .then((data) => {

        setLoading(false);
        //setFetched(true);
        console.log("post returned data: ", data)
        setReserveDate("");
        handleClose();
        setReFetch(!reFetch);
      }).catch(err => {
        setLoading(false);
        setFetchError(true);
        console.log("fetch error: ", err)
      });

  }

  const deleteItem = async (itemName) => {
    let currentUrl = window.location.href.split("/");
    currentUrl.splice(0, 3);
    //reserveInfo = {asd: 9}
      fetch(`${serverUrl}/deleteItem`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({path: currentUrl, itemName})
      })
      .then((res) => res.json())
      .then((data) => {

        setLoading(false);
        //setFetched(true);
        console.log("post returned data: ", data)
        handleClose();
      }).catch(err => {
        setLoading(false);
        setFetchError(true);
        console.log("fetch error: ", err)
      });

  }

  const addNewItem = async () => {
    let currentUrl = window.location.href.split("/");
    currentUrl.splice(0, 3);
    //reserveInfo = {asd: 9}
      fetch(`${serverUrl}/addNewItem`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({path: currentUrl, newDeviceName, newDeviceType, newDeviceQuantity, newDeviceDesc, newDeviceLocation, newDeviceStickerSize, newDeviceSupplier})
      })
      .then((res) => res.json())
      .then((data) => {

        setLoading(false);
        //setFetched(true);
        console.log("post returned data: ", data)
        setNewDeviceName("") 
        setNewDeviceType("")
        setNewDeviceQuantity("")
        setNewDeviceDesc("")
        setNewDeviceLocation("")
        setNewDeviceStickerSize("")
        setNewDeviceSupplier("")
        handleNewItemClose();
        setReFetch(!reFetch);
      }).catch(err => {
        setLoading(false);
        setFetchError(true);
        console.log("fetch error: ", err)
      });

  }

  const acceptEditedItem = async () => {
    let currentUrl = window.location.href.split("/");
    currentUrl.splice(0, 3);
    //reserveInfo = {asd: 9}
      fetch(`${serverUrl}/updateItem`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({path: currentUrl, editDeviceName, editDeviceType, editDeviceQuantity, editDeviceDesc, editDeviceLocation, editDeviceStickerSize, editDeviceSupplier})
      })
      .then((res) => res.json())
      .then((data) => {

        setLoading(false);
        //setFetched(true);
        console.log("post returned data: ", data)
        setEditDeviceName("") 
        setEditDeviceType("")
        setEditDeviceQuantity("")
        setEditDeviceDesc("")
        setEditDeviceLocation("")
        setEditDeviceStickerSize("")
        setEditDeviceSupplier("")
        setEditMode(false)
        setReFetch(!reFetch);
      }).catch(err => {
        setLoading(false);
        setFetchError(true);
        console.log("fetch error: ", err)
      });

  }

  const launchEditMode = () => {
        setEditDeviceName(data.result[0].type) 
        setEditDeviceType(data.result[0].name)
        setEditDeviceQuantity(data.result[0].description)
        setEditDeviceDesc(data.result[0].supplier)
        setEditDeviceLocation(data.result[0].quantity)
        setEditDeviceStickerSize(data.result[0].location)
        setEditDeviceSupplier(data.result[0].stickerSize)
        setEditMode(true)
  }

  const handleEditDeviceTypeChange = useCallback((e) => {
    setEditDeviceType(e.target.value);
  }, []);
  //console.log("DATA: ", data)
  //console.log("newItemOpen: ", newItemOpen)
  console.log("reservation: ", data)

  return (
    <div className="mobileContainer">
      <div className="subContainer">
        {data.type === "mainSite" ?
        
        <div className="myMobileHeader">

          <div className="mobileTitle">
            <h1 style={{ fontWeight: "bold", fontSize: "3.5em" }}>Laitteet</h1>
            <h2 style={{ color: "#6E6E73" }}>Hankkeen Nimi </h2>
            <p>
              Tulevaisuuden tuotekehityksellä EP-jalostusarvo nousiin EDIH-mallia
              hyödyntäen (Investoinnit)
            </p>
            <h2 style={{ color: "#6E6E73" }}>Hakemusnumero</h2>
            <p>309240</p>


            <h2 style={{ color: "#6E6E73" }}>Hankekoodi</h2>
            <p>A77090</p>

            {
              window.location.href !== `${clientURL}/` &&
              <Button style={{
                alignSelf: "center", maxHeight: "5vh", alignSelf: "flex-end",
                borderRadius: "25px", textTransform: "none"
              }} variant="contained"
                onClick={() => window.location.href = clientURL}>Pääsivulle</Button>
            }
          </div>


        </div>

        : null}

        <div className="header">{loading && <CircularProgress />}</div>

        {fetchError && "Oops, didn't find anything here..."}

        {Object.keys(data).length > 0 && fetchError === false && (
          <Paper
            variant="elevation"
            square
            elevation={24}
            className="mobileData"
            sx={{
              backgroundColor:
                "#1D1D1F",
              color: "white",
              //borderRadius: "25px"
            }}
          >
            {
              data.type === "mainSite" &&
              <div style={{ display: "flex", flexDirection: "column", width: "80%" }}>
                <h2 style={{ textAlign: "center" }}>Kategoriat</h2>
                <div style={{ display: "flex", justifyContent: "space-between", height: "25vh" }}>
                  {data.result.map((item, index) => {
                    console.log("item", item)
                    return (<div key={item} style={{
                      textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around",
                      marginBottom: "15%"
                    }}>
                      {item === "3D" && <img src={threeDeeIcon} alt="vrIcon" style={{ color: "white", fill: "white" }} />}
                      {item === "XR" && <img src={vrIcon} alt="vrIcon" style={{ color: "white", fill: "white" }} />}
                      {item === "IOT" && <img src={iotIcon} alt="vrIcon" style={{ color: "white", fill: "white" }} />}
                      <Button variant="contained" style={{ color: "white", borderRadius: "25px", maxWidth: "80%" }} href={`${window.location}${item}`}>
                        {item}
                      </Button>
                    </div>
                    )
                  })}
                </div>
              </div>
            }
            {
              data.type === "device" &&

              <div >
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Varaa laite</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Kirjoita nimesi ja palautuspäivämäärä.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Etunimi sukunimi"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setReserverName(e.target.value)
                      }
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker onChange={(newValue) => setReserveDate(newValue)}/>
                    </LocalizationProvider>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Peruuta</Button>
                    <Button onClick={() => reserveItem(true)}>Vahvista</Button>
                  </DialogActions>
                </Dialog>

               
                <h5 style={{ color: "#6E6E73" }}>Polku: {currentPathString}</h5>
                <h2 style={{ textAlign: "center" }}>Laite</h2>
                {editMode === false ?
                <div>
                <div className="row"><h3>Varaustilanne</h3><p style={{ textAlign: "right" }}>{data.result[0].reservation === "" ? "Vapaa" : data.result[0].reservation}</p></div>
                {data.result[0].reservation === "" ? null : 
                
                  <div className="row"><h3>Varaus päättyy</h3><p style={{ textAlign: "right" }}>
                    
                    {data.result[0].returnDate !== "" ? format(new Date(data.result[0].returnDate), 'MMMM d, yyyy h:mm a')
                    : ""}</p>
                  </div>
                }
                <div className="row"><h3>Tyyppi</h3><p style={{ textAlign: "right" }}>{data.result[0].type}</p></div>
                <div className="row"><h3>Nimi</h3><p style={{ textAlign: "right" }}>{data.result[0].name}</p></div>
                <div className="row"><h3>Lisätiedot</h3> <p style={{ textAlign: "right" }}>{data.result[0].description}</p></div>
                <div className="row"><h3>Toimittaja</h3><p style={{ textAlign: "right" }}>{data.result[0].supplier}</p></div>
                <div className="row"><h3>Määrä</h3><p style={{ textAlign: "right" }}>{data.result[0].quantity}</p></div>
                <div className="row"><h3>Sijainti</h3> <p style={{ textAlign: "right" }}>{data.result[0].location}</p></div>
                <div className="row"><h3>Tarran koko</h3> <p style={{ textAlign: "right" }}>{data.result[0].stickerSize}</p></div>
                <div className="row"><h3>QR-osoite</h3>  <p>{data.result[0].qrURL}</p></div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => handleClickOpen()}>Varaa</Button>
                {data.result.reservation !== "" ? <Button variant="outlined" onClick={() => reserveItem(false)}>Palauta</Button> : null}
                
                {localStorage.getItem("username") === "admin" && editMode === false && <IconButton onClick={() => launchEditMode()} variant="contained" style={{ color: "white", borderRadius: "25px", maxWidth: "80%" }}>
                        <EditIcon/>
                </IconButton>}
                </div>
                </div>
                  : 
                  <div>
                <div className="row"><h3>Varaustilanne</h3><CustomTextField style={{ textAlign: "right" }}>{data.result[0].reservation === "" ? "Vapaa" : data.result[0].reservation}</CustomTextField></div>
                {data.result.reservation === "" ? null : <div className="row"><h3>Varaus päättyy</h3><CustomTextField style={{ textAlign: "right" }}>{data.result[0].returnDate}</CustomTextField></div>}
                <div className="row"><h3>Tyyppi</h3><CustomTextField onChange={(e) => setEditDeviceType(e.target.value)} style={{ textAlign: "right"}} value={editDeviceType} multiline ></CustomTextField></div>
                <div className="row"><h3>Nimi</h3><CustomTextField onChange={(e) => setEditDeviceName(e.target.value)} style={{ textAlign: "right"}} value={editDeviceName} multiline></CustomTextField></div>
                <div className="row"><h3>Lisätiedot</h3> <CustomTextField onChange={(e) => setEditDeviceDesc(e.target.value)} style={{ textAlign: "right" }} value={editDeviceDesc} multiline></CustomTextField></div>
                <div className="row"><h3>Toimittaja</h3><CustomTextField onChange={(e) => setEditDeviceSupplier(e.target.value)} style={{ textAlign: "right" }} value={editDeviceSupplier} multiline></CustomTextField></div>
                <div className="row"><h3>Määrä</h3><CustomTextField onChange={(e) => setEditDeviceQuantity(e.target.value)} style={{ textAlign: "right" }} value={editDeviceQuantity} multiline></CustomTextField></div>
                <div className="row"><h3>Sijainti</h3> <CustomTextField onChange={(e) => setEditDeviceLocation(e.target.value)} style={{ textAlign: "right" }} value={editDeviceLocation} multiline></CustomTextField></div>
                <div className="row"><h3>Tarran koko</h3> <CustomTextField onChange={(e) => setEditDeviceStickerSize(e.target.value)} style={{ textAlign: "right" }} value={editDeviceStickerSize} multiline></CustomTextField></div>
              
                <Button onClick={() => setEditMode(false)} variant="contained" style={{ color: "white", borderRadius: "25px", alignSelf: "right", margin: "auto", maxWidth: "80%" }}>
                        Peruuta
              </Button>
              <Button onClick={() => acceptEditedItem()} variant="contained" style={{ color: "white", borderRadius: "25px", alignSelf: "right", margin: "auto", maxWidth: "80%" }}>
                        Hyväksy
              </Button>
                {/*<IconButton onClick={() => setEditMode(true)} variant="contained" style={{ color: "white", borderRadius: "25px", alignSelf: "right", margin: "auto", maxWidth: "80%" }}>
                        <EditIcon/>
              </IconButton>*/}
                </div>
                  }
              </div>
            }
            {
              data.type === "subCategory" &&
              <div style={{display: "flex", flexDirection: "column"}}>
                 <Dialog open={newItemOpen} onClose={handleNewItemClose}>
                  <DialogTitle>Lisää uusi laite</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Syötä tarvittavat tiedot
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="deviceType"
                      label="Laitteen tyyppi"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceType(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="deviceName"
                      label="Laitteen nimi"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceName(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="deviceDesc"
                      label="Lisätiedot"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceDesc(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="DeviceQuantity"
                      label="Määrä"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceQuantity(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="deviceLocation"
                      label="Sijainti"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceLocation(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="deviceStickerSize"
                      label="Tarran koko"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceStickerSize(e.target.value)
                      }
                    />
                     <TextField
                      autoFocus
                      margin="dense"
                      id="DeviceSupplier"
                      label="Toimittaja"
                      fullWidth
                      variant="standard"
                      onChange={(e) => 
                        setNewDeviceSupplier(e.target.value)
                      }
                    />
                  
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleNewItemClose}>Peruuta</Button>
                    <Button onClick={() => addNewItem()}>Vahvista</Button>
                  </DialogActions>
                </Dialog>
                <h5 style={{ color: "#6E6E73" }}>Polku: {currentPathString}</h5>
                <h2 style={{ textAlign: "center" }}>Laitteet</h2>
                <EnhancedTable data={data.result ? data.result : [{}]} />
                {/* data.result.map((item, index) => {
                 return <div key={item.name} style={{
                    textAlign: "center",
                    marginBottom: "15%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}>
                    
                    {/*<Button variant="contained" style={{ color: "white", borderRadius: "25px", margin: "auto", maxWidth: "80%" }} href={`${window.location}/${item.name}`}>
                      {item.name}
                </Button>
                <div style={{display: "flex", flexAlign: "center", flexDirection: "row",
              justifyContent: "space-evenly"}}>
                <Button
                  variant="contained"
                  //disabled={fruitsInBasket.length >= FRUITS.length}
                  onClick={() => handleAddFruit(item._id)}
                >
                    {item.name}
                </Button>
                {
                      item.reservation === "" ? "Vapaa" : "Varattu"
                    }
                  <List sx={{ mt: 1 }}>
                    <TransitionGroup>
                    {(drawerOpen[item._id] && drawerOpen[item._id] === true) && (
                       <Collapse key={item._id}>
                       <ListItem><ListItemText primary={item.info.description} /></ListItem>
                      <ListItem><ListItemText primary={item.info.qrURL} /></ListItem>
                      <ListItem><ListItemText primary={item.info.quantity} /></ListItem>
                      <ListItem><ListItemText primary={item.info.returnDate} /></ListItem>
                      <ListItem><ListItemText primary={item.info.stickerSize} /></ListItem>
                      <ListItem><ListItemText primary={item.info.supplier} /></ListItem>
                      </Collapse>
                    )}
                    
                    </TransitionGroup>
                  </List>
                </div>
                    <IconButton onClick={() => deleteItem(data.result)} variant="contained" style={{ color: "white", borderRadius: "25px", margin: "auto", maxWidth: "80%" }}>
                        <DeleteIcon/>
                    </IconButton>
                  </div>           
                }) */}
                {localStorage.getItem("username") === "admin" && 
                    <div>
                    <Button onClick={() => handleNewItemOpen()} variant="contained" style={{ color: "white", borderRadius: "25px", margin: "auto", maxWidth: "80%" }}>
                      Lisää uusi
                    </Button>
                    </div>
                    }
              </div>
            }
            
            {
              data.type === "category" &&
              <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                <h5 style={{ color: "#6E6E73" }}>Polku: {currentPathString}</h5>
                <h2 style={{ textAlign: "center" }}>Ala-kategoriat</h2>
                {Object.keys(data.result).map((item, index) => {
                  console.log("mapista: ", item)
                  return <div key={item} style={{
                    textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around",
                    marginBottom: "15%"
                  }}>
                    <Button variant="contained" style={{ color: "white", borderRadius: "25px", margin: "auto", maxWidth: "80%" }} href={`${window.location}/${item}`}>
                      {item.name}
                    </Button>
                  </div>
                })}

              </div>
            }
            {
              data.type !== "mainSite" &&
              <Button style={{
                alignSelf: "flex-start", maxHeight: "5vh", marginLeft: "7%",
                borderRadius: "25px", textTransform: "none", marginTop: "10%", marginBottom: "5%",
                color: "#1D1D1F", backgroundColor: "white"
              }}
                variant="contained" onClick={() => {
                  const currentUrl = window.location.href.split("/");
                  const newUrl = currentUrl.slice(0, currentUrl.length - 1).join("/");
                  window.location.href = newUrl
                }}>
                Edellinen
              </Button>
            }

          </Paper>
        )}
      </div>
      <div className="logos">
        <img style={{ height: "5vh" }} src={seamk} alt="Seamk logo" />
        <img
          style={{ height: "5vh" }}
          src={vipuvoimaa}
          alt="Vipuvoimaa logo"
        />
        <img style={{ height: "5vh" }} src={eakr} alt="Eakr logo" />
      </div>
    </div>
  );
}
